$(function() {
  widgetWidth = $('.widgets').width();
  widgetHeight = $('.widgets').position().top + parseInt($('.widgets').css('marginTop')) - 75 - 6;

  $(window).resize(function() {
    widgetWidth = $('.widgets').width();
  });

  $(window).scroll(function() {
    /* scroll to top fade */
    if ($(window).scrollTop() > 0) {
      $('.scroll-to-top').css('opacity', '0.7');
    } else {
      $('.scroll-to-top').css('opacity', '0');
    }

    if ($(window).width() >= 768) {
      if ($(window).scrollTop() >= widgetHeight) {
        $('.widgets').addClass('fixed');
        $('.widgets').width(widgetWidth);
        $('.article').css({
          paddingLeft: widgetWidth + 50,
          width: 'calc(100% + ' + widgetWidth + 'px)',
        });
      } else {
        $('.widgets').removeClass('fixed');
        $('.widgets').width('');
        $('.article').css({
          paddingLeft: '',
          width: '',
        });
      }
    }
  });
  
  /* scroll to top click handler */
  $('.scroll-to-top').click(function() {
    $('html, body').animate({
      scrollTop: 0
    }, $(window).scrollTop() / 3);
  });

  /* navbar search */
  $('.navbar-search .icon').click(function() {
    $('.navbar-search').toggleClass('active');
  });

  /* hamburger button */
  $('.hamburger-button').click(function() {
    $('.hamburger-menu').toggleClass('active');
  });
});
